import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseLink from './links/BaseLink';

const SectionStyles = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${(props) => props.backgroundColor};

  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;
// Baselink back up to parent category page, aka Back link
function BackLinkSection(data) {
  const { link, paddingTop, paddingBottom } = data;
  if (link) {
    return (
      <SectionStyles
        paddingTop={paddingTop || '40px'}
        paddingBottom={paddingBottom || '20px'}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              {link && (
                <BaseLink
                  src={link.src}
                  linkType="reversedArrowLink"
                >
                  {link.text}
                </BaseLink>
              )}
            </div>
          </div>
        </div>
      </SectionStyles>
    );
  }
  return null;
}

BackLinkSection.propTypes = {
  data: PropTypes.PropTypes.shape({
    paddingTop: PropTypes.string,
    paddingBottom: PropTypes.string,
    link: PropTypes.shape({
      text: PropTypes.string,
      src: PropTypes.string
    })
  })
};

BackLinkSection.defaultProps = {
  data: null
};

export default BackLinkSection;
