import React, { useState } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

function sanitizeQueryString(userInput) {
  return DOMPurify.sanitize(userInput);
}

const SearchForm = styled.form`
  margin: 0 0 24px 0;
  input[name='FormButton'] {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.orange_65};
    font-weight: 600;
    padding: 9px 10px 9px 10px;
    transition: background-color 50ms ease-in-out, border-color 50ms ease-in-out;
    border-radius: 0 4px 4px 0;
    height: 40px;
    border: 1px solid ${(props) => props.theme.colors.orange_65};

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    :focus {
      border-radius: 0px 4px 4px 0;
      outline: none;
    }
    :hover,
    :active {
      opacity: 0.8;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
`;

const InputField = styled.input`
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.orange_50};
  border-radius: 4px 0 0 4px;
  box-sizing: border-box;
  box-shadow: none;
  color: ${(props) => props.theme.colors.grey_40};
  font-size: 14px;
  height: 40px;
  line-height: 20px;
  margin: 0;
  outline: 0;
  padding: 0 12px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @media screen and (max-width: 576px) {
    font-size: 8px;
  }

  :focus {
    border: 1px solid ${(props) => props.theme.colors.orange_50};
    color: #555;
  }
  /* clears the ‘X' from Internet Explorer */
  ::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  ::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X' from Chrome */
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`;

function SignUpPostmanEmail() {
  const [query, setQuery] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  /* open query in another tab */
  function toggleSearchResult() {
    const sLink = document.createElement('a');
    sLink.target = '_blank';
    sLink.href = sanitizeQueryString(`${query}`);
    sLink.click();
  }

  const handleChange = (e) => {
    const cleanQuery = sanitizeQueryString(e);
    setQuery(
      `https://identity.getpostman.com/signup?addAccount=1&email=${cleanQuery}`
    );
    setIsButtonDisabled(e.trim() === '');
  };

  const handleButtonClick = () => {
    if (isButtonDisabled) return;
    const res = sanitizeQueryString(document.getElementById('input').value);
    setQuery(
      `https://identity.getpostman.com/signup?addAccount=1&email=${res}`
    );
    toggleSearchResult();
  };

  /* search on enter key */
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      toggleSearchResult();
    }
  };

  return (
    <SearchForm action="#" method="POST" target="_blank">
      <InputWrapper>
        <InputField
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyPress}
          type="search"
          placeholder="name@company.com"
          autoComplete="off"
          aria-label="name@company.com"
          id="input"
        />
        {/* Do not use Aether-Marketing Buttons for Zendesk Search Form */}
        <input
          id="SignUp"
          type="button"
          name="FormButton"
          value="Sign Up for Free"
          onClick={isButtonDisabled ? null : handleButtonClick}
          style={{
            cursor: isButtonDisabled ? 'not-allowed' : 'pointer'
          }}
        />
      </InputWrapper>
    </SearchForm>
  );
}

export default SignUpPostmanEmail;
