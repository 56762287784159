import styled from 'styled-components';
import { theme } from '../../Theme';

export const BaseButtonStyles = styled.button`
  align-items: center;
  appearance: none;
  border: 1px solid transparent;
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  padding: 8px 16px;
  margin: 0 16px 0 0;
  user-select: none;
  transition: ${theme.transitions.all};
  text-align: center;

  &:hover,
  &:active {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &.isDisabled {
    color: ${theme.colors.grey_20};
    cursor: not-allowed;
    background-image: none !important;
    background: ${theme.colors.grey_2};
    border-color: transparent;
  }
`;

export const Primary = styled(BaseButtonStyles)`
  color: ${theme.colors.white} !important;
  background-color: ${theme.colors.orange_65};
  transform: translateZ(0px);
  transition: none !important;

  &:hover,
  &:active {
    background-color: ${theme.colors.orange_55};
    border: 1px solid transparent;
    -webkit-appearance: none;
    border-inline: 1px solid ${theme.colors.orange_55};
  }
`;

export const Secondary = styled(BaseButtonStyles)`
  border: 1px solid ${theme.colors.grey_40};
  color: ${theme.colors.grey_60} !important;

  &:hover,
  &:active {
    border: 1px solid ${theme.colors.grey_60};
  }
`;
