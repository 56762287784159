/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */

import { SideXSide, Divider, FooterLinkStyles } from 'aether-marketing';
import Hero from './Shared/heroes/Hero';
import footer from './Shared/footer';
import CallOut from './Shared/CallOut';
import CardBasicGrid from './Shared/cards/CardBasicGrid';
import Table from './Shared/Table';
import HeroWithLargeImage from './Shared/heroes/HeroWithLargeImage';
import EmbedForm from './Shared/EmbedForm';
import HeroStacked from './Shared/heroes/HeroStacked';
import CenteredImage from './Shared/CenteredImage';
import CaseStudySection from './Shared/CaseStudySection';
import BackLinkSection from './Shared/BacklinkSection';
import LeftNav from './Shared/sidebar/LeftNav';
import TextSection from './Shared/TextSection';
import Feature from './Shared/Feature';
import SignupInputBox from './Shared/SignupInputBox';
import FaqSection from './Shared/FaqSection';
import Slider from './Shared/Slider';
import CaseStudyThreeAcross from './Shared/CaseStudyThreeAcross';
import LogoWallSection from './Shared/LogoWallSection';
import amplitude from './Shared/amplitude';
import DownloadsHero from './Shared/downloads/DownloadsHero';
import SideBySideDataService from './Shared/SideBySideDataService';
import ListRich from './Shared/ListRich';
import LeftTitleWithText from './Shared/LeftTitleWithText';
import ImageXText from './Shared/ImageXText';
import QRHero from './Shared/QRHero';
import TableSideXSide from './Shared/TableSideXSide';
import MultiTableSideXSide from './Shared/MultiTableSideXSide';
import SideBySide from './Shared/SideBySide';
import BaseButton from './Shared/buttons/BaseButton';
import BaseLink from './Shared/buttons/BaseLink';
import PreFooterSideBySideLottie from './Shared/PreFooterSideBySideLottie';
import heroWithModalAndImage from './Shared/heroWithModalAndImage';
import HeroStackedVideo from './Shared/HeroStackedVideo';
import SideBySideTabs from './Shared/SideBySideTabs';
import logoWallSectionV6 from './Shared/logoWallSectionV6';
import ModalComponent from './Shared/ModalComponent';
import SVGComponent from './Shared/SVGComponent';


export default {
  Hero,
  footer,
  CallOut,
  CardBasicGrid,
  Table,
  HeroWithLargeImage,
  Divider,
  BaseButton,
  BaseLink,
  FooterLinkStyles,
  SideXSide,
  EmbedForm,
  HeroStacked,
  CenteredImage,
  CaseStudySection,
  BackLinkSection,
  LeftNav,
  TextSection,
  Feature,
  SignupInputBox,
  FaqSection,
  Slider,
  CaseStudyThreeAcross,
  LogoWallSection,
  amplitude,
  DownloadsHero,
  SideBySideDataService,
  ListRich,
  LeftTitleWithText,
  ImageXText,
  QRHero,
  TableSideXSide,
  MultiTableSideXSide,
  SideBySide,
  PreFooterSideBySideLottie,
  heroWithModalAndImage,
  HeroStackedVideo,
  SideBySideTabs,
  logoWallSectionV6,
  SVGComponent,
  ModalComponent
};
